import React from "react";

const Loader = () => {
  return (
    <div className="contentL">
    <div className="loading">
    <div></div>
    <div></div>
  </div>  
  </div>
  );
};

export default Loader;
